'use client';

//	Modules
import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
//	Components
import Link from 'next/link';
import Image from 'next/image';
import { SideImage } from '@/components/sideimage/SideImage';
import { Logo } from '@/components/logo/Logo';
import styled from '@emotion/styled';
import { PreLoader } from '@/components/preloader/PreLoader';
import Cookies from 'js-cookie';
//	Assets
import style from '../phase.module.scss';
import CustomPhoneNumberInput from '@/components/phonecountry/PhoneNumber';
import FormSubmitButton from '@/components/Buttons/FormSubmitButton';
import Heading1 from '@/components/Typography/Heading1';
import Paragraph from '@/components/Typography/Paragraph';
import AuthLayout from '@/components/Layout/auth';

const Wrapper = styled('div')`
	.login-form {
		border-radius: 0;

		&__request-messages-area {
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 1.67;
			margin: 80px auto;
			text-transform: capitalize;

			&.error {
				font-weight: 400;
				font-size: 10px;
				color: #f2111c;
				margin: 0 0 10px;
			}
		}
	}
`;

export default function LogIn() {
	const router = useRouter();

	const [requestStatusMessageError, setRequestStatusMessageError] =
		useState(''); //	Validation error message state
	const [isPassEyeClicked, setIsPassEyeClicked] = useState(false); //	Password eye icon to see the password
	const [isSendingNow, setIsSendingNow] = useState(false); //	Loader state

	// State to hold form data
	const [formData, setFormData] = useState({
		phone: '',
		password: '',
	});

	// Handle password see section
	function handleTogglePassword() {
		if (!isPassEyeClicked) {
			setIsPassEyeClicked(true);
		} else {
			setIsPassEyeClicked(false);
		}
	}

	// Handle form input changes
	function handleInputChange(e) {
		const { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});
	}

	function handlePhoneInputChange(event) {
		let { value, name } = event.target;

		// If the input is for the phone field, remove leading zeros (if any)
		if (name === 'phone' && value.startsWith('0')) {
			value = value.substring(1);
		}

		setFormData({
			...formData,
			[name]: value,
		});
	}

	// Handle registration form submit
	function handleFormSubmit(e) {
		e.preventDefault();
		const { phone, password } = formData;
		const isFormValid = phone.trim() !== '' && password.trim() !== '';

		const loginOption = {
			method: 'POST',
			body: JSON.stringify({
				phone_number:
					process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE'
						? '254' + phone
						: '250' + phone,
				password: password,
				country_code: process.env.NEXT_PUBLIC_COUNTRY_CODE,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow',
		};

		fetch(
			`${process.env.NEXT_PUBLIC_CMS_URL}${process.env.NEXT_PUBLIC_LOGIN_API_PATH}`,
			loginOption
		)
			.then((result) => result.json())
			.then((result_data) => {
				const { message, token } = result_data;
				// localStorage.setItem('token', token);
				if (message && token) {
					// window.scrollTo({ top: 0, behavior: "smooth" });
					Cookies.set('token', token[0]);
				} else {
					setRequestStatusMessageError(message);
				}

				if (token) {
					const profileOption = {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${token[0]}`,
						},
					};

					setIsSendingNow(true);

					setTimeout(() => {
						fetch(
							`${process.env.NEXT_PUBLIC_CMS_URL}${process.env.NEXT_PUBLIC_PROFILE_API_PATH}`,
							profileOption
						)
							.then((result) => result.json())
							.then((result_data) => {
								const {
									data: {
										owner: { fullname: ownerFullname },
										owner: { email: ownerEmail },
										owner: { profile_photo_url: ownerProfilePhotoUrl },
										owner: { phone_number: owner_phone_number },
										country_code,
										email,
										location,
										role_slug,
										owner: { id: ownerId },
										business_name,
										contact_person,
										business,
										created_at,
										date_of_approved_or_rejected,
										verified_by,
										verification_status,
										documents,
										address,
										metadata,
										customer_number,
										ncd_active,
										id,
									},
								} = result_data;
								Cookies.set('ncd_active', ncd_active);
								Cookies.set('business_customer_id', id);

								const businessType = business ? business.name : null;
								setIsSendingNow(false);

								localStorage.setItem(
									'pharma_customer',
									JSON.stringify({
										fullname: ownerFullname,
										owner_email: ownerEmail,
										phone_number: owner_phone_number,
										profile_photo_url: ownerProfilePhotoUrl,
										country_code,
										email,
										location,
										role: role_slug,
										id: ownerId,
										business_name,
										contact_person,
										businessType: businessType,
										created_at,
										date_of_approved_or_rejected,
										verified_by,
										verification_status,
										documents,
										address,
										metadata,
										customer_number,
										token: token[0],
										ncd_active,
										business_customer_id: id,
									})
								);
								if (isFormValid) {
									window.location.href = '/';
								}
							});
					}, 1500);
				}
			});
	}

	return (
		<AuthLayout>
			<div
				className={`d-flex`}
				style={{ marginTop: 'auto', marginBottom: 'auto' }}>
				<form onSubmit={handleFormSubmit}>
					<div>
						<Heading1 title={'Welcome Back'} />
						{isSendingNow && <PreLoader />}

						<Paragraph>
							Please add the following details to access your account
						</Paragraph>

						<CustomPhoneNumberInput
							phone={formData.phone}
							handleInputChange={handlePhoneInputChange}
						/>

						<div className={style.inputWithIcon}>
							<input
								className={style.passwordInput}
								type={isPassEyeClicked ? 'text' : 'password'}
								name='password'
								placeholder='Password'
								autoComplete='off'
								required
								onChange={handleInputChange}
							/>
							{/* <i className='fa fa-lock fa-lg fa-fw' aria-hidden='true'></i> */}
							<i>
								{/* <FontAwesomeIcon icon={faLock} /> */}
								<Image
									src='/images/lock-key.svg'
									alt='Lock & Key Icon'
									width={18}
									height={18}
								/>
							</i>

							<FontAwesomeIcon
								icon={isPassEyeClicked ? faEyeSlash : faEye}
								className={style.togglePassword}
								onClick={handleTogglePassword}
							/>
						</div>

						{requestStatusMessageError && (
							<Wrapper>
								<div className={`login-form__request-messages-area error`}>
									{requestStatusMessageError === 'Validation errors'
										? `Invalid Phone Number. Wrong Phone Format.`
										: requestStatusMessageError}
								</div>
							</Wrapper>
						)}

						<Paragraph>
							<Link
								href={`${process.env.NEXT_PUBLIC_CMS_URL}/reset-token/pharma?country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}`}
								style={{ textDecoration: 'none' }}>
								Forgot Password?
							</Link>{' '}
						</Paragraph>

						<div>
							<FormSubmitButton disabled={isSendingNow} text={'Sign In'} />

							<Paragraph>
								Don&apos;t have an account?{' '}
								<Link href='/registration' style={{ textDecoration: 'none' }}>
									Sign Up
								</Link>
							</Paragraph>
						</div>
					</div>
				</form>
			</div>
		</AuthLayout>
	);
}
