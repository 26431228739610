import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import style from './CustomPhoneNumberInput.module.css';

const CustomPhoneNumberInput = ({
	handleInputChange,
	phone = '',
	disabled_status = false,
	required_status = true,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(
		process.env.NEXT_PUBLIC_COUNTRY_CODE
	);

	const handleCountryChange = (e) => {
		setSelectedCountry(e.target.value);
	};

	return (
		<div className={style.telInputWithIcon}>
			<input
				className={style.telInput}
				type='tel'
				minLength={9}
				maxLength={9}
				name='phone'
				value={phone}
				placeholder='Phone Number'
				onChange={handleInputChange}
				required={required_status}
				disabled={disabled_status}
			/>
			<i style={{ padding: '12px' }}>
				<select
					className={style.customDropdown}
					disabled={true}
					value={selectedCountry}
					onChange={handleCountryChange}>
					<option value='IN'>🇮🇳 +91</option>
					<option value='KE'>🇰🇪 +254</option>
					<option value='RW'>🇷🇼 +250</option>
				</select>
				<div className={style.cvl}></div>
			</i>
		</div>
	);
};

// Define PropTypes
CustomPhoneNumberInput.propTypes = {
	handleInputChange: PropTypes.func.isRequired, // Validate handleInputChange prop as a function and required
	phone: PropTypes.string, // Validate phone prop as a string
	disabled_status: PropTypes.bool, // Validate disabled_status prop as a boolean
	required_status: PropTypes.bool, // Validate required_status prop as a boolean
};

export default CustomPhoneNumberInput;
