import Link from 'next/link';
import { SideImage } from '../sideimage/SideImage';

const AuthLayout = ({ children }) => {
	return (
		<div className={`container-fluid vh-100`}>
			{/* Logo Section */}
			<div className='row  h-100'>
				<div className='col px-3 m-0 d-flex flex-column justify-content-center align-items-center'>
					<div className='w-100 px-3 mt-3 mb-5'>
						<div style={{ width: 100 }}>
							<a href={'/'}>
								<img
									src='/images/kasha_new.png'
									alt='Kasha Logo'
									className='w-100 mx-auto'
								/>
							</a>
						</div>
					</div>

					{children}
				</div>
				<div className='col-5 p-0 m-0 d-none d-sm-block'>
					<SideImage />
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
