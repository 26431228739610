import './SideImage.style.css';

export function SideImage() {
	return (
		<div className="image-container h-100 bg-light" style={{ width: '100%', height: '100%', position: 'relative' }}>
			<img
				style={{
					opacity: '0.2',
					objectFit: 'cover', // This will cover the area without overflow
					width: '100%', // Ensure the image spans the full width of the container
					height: '100%', // Ensure the image spans the full height of the container
					position: 'absolute', // Position the image absolutely within its relative container
				}}
				src="/images/reg-right-image.avif"
				alt="Side Image"
				loading="eager"
			/>
		</div>
	);
}