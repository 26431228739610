import Image from 'next/image';
import Link from 'next/link';
import './Logo.style.css';

export function Logo() {
	return (
		<div className=''>
			<a href='/'>
				<Image
					src='/images/kasha_new.png'
					alt='Kasha Logo'
					width={100}
					height={24}
					priority
				/>
			</a>
		</div>
	);
}
